"use strict";
exports.__esModule = true;
var thehum_1 = require("./thehum");
var HumController = /** @class */ (function () {
    function HumController() {
        this.lastTime = 0;
        this.timeElapsed = 0;
        this.droneStarted = false;
        this.miseryStarted = false;
        this.brexmageddonStarted = false;
        this.playing = true;
        this.hum = new thehum_1.TheHum();
    }
    HumController.prototype.initialise = function () {
        var _this = this;
        this.scrollElement = document.documentElement;
        this.droneStartElement = document.getElementById('gandhi');
        this.miseryStartElement = document.getElementById('more');
        this.brexmageddonStartElement = document.getElementById('pain');
        this.backgroundElement = document.getElementById('background');
        this.stopMusicElement = document.getElementById('stop');
        this.audioElement = document.querySelector('audio');
        this.hum.initialise(this.droneStartElement, this.miseryStartElement, this.brexmageddonStartElement, this.audioElement);
        this.scrollListener = function () { return _this.onScroll(); };
        document.addEventListener('scroll', this.scrollListener);
        this.lastTime = new Date().getTime();
        this.stopMusicElement.addEventListener('click', function () {
            _this.toggleMusic();
        });
        this.audioElement.addEventListener('ended', function () {
            _this.onEnded();
        });
    };
    HumController.prototype.onScroll = function () {
        var _a = this, hum = _a.hum, brexmageddonStarted = _a.brexmageddonStarted, brexmageddonStartElement = _a.brexmageddonStartElement, scrollElement = _a.scrollElement;
        var startRect = brexmageddonStartElement.getBoundingClientRect();
        if (!brexmageddonStarted && startRect.top <= window.innerHeight) {
            this.brexmageddonStarted = true;
            hum.mute();
            var root = document.getElementById('root');
            root.className = "root hidden";
            root.style.display = "none";
            this.backgroundElement.style.opacity = "1";
            hum.startMusic();
        }
    };
    HumController.prototype.toggleMusic = function () {
        if (this.playing) {
            this.hum.pauseMusic();
            this.stopMusicElement.textContent = '[resume]';
        }
        else {
            this.hum.startMusic();
            this.stopMusicElement.textContent = '[pause]';
        }
        this.playing = !this.playing;
    };
    HumController.prototype.onEnded = function () {
        this.hum.pauseMusic();
        this.playing = false;
        this.stopMusicElement.textContent = '[play again]';
    };
    return HumController;
}());
exports.HumController = HumController;
