"use strict";
exports.__esModule = true;
require("styles/index.scss");
var PeriodicFunction;
(function (PeriodicFunction) {
    PeriodicFunction["SINE"] = "Sine";
    PeriodicFunction["COSINE"] = "Cosine";
})(PeriodicFunction || (PeriodicFunction = {}));
var WaveType;
(function (WaveType) {
    WaveType["SAWTOOTH"] = "sawtooth";
    WaveType["SINE"] = "sine";
    WaveType["SQUARE"] = "square";
})(WaveType || (WaveType = {}));
var Oscillator = /** @class */ (function () {
    function Oscillator(node, options) {
        this.node = node;
        this.options = options;
    }
    Oscillator.prototype.update = function (t) {
        var _a = this, node = _a.node, options = _a.options;
        var frequency = options.frequency, period = options.period, amplitude = options.amplitude, method = options.method;
        var next = 0;
        if (method === PeriodicFunction.SINE)
            next = frequency + amplitude * Math.sin(t * period);
        else
            next = frequency + amplitude * Math.cos(t * period);
        node.frequency.setValueAtTime(next, t);
    };
    Oscillator.prototype.start = function () {
        this.node.start();
    };
    Oscillator.prototype.stop = function () {
        this.node.stop();
    };
    return Oscillator;
}());
var Ear = /** @class */ (function () {
    function Ear(context, position, startElement, endElement, maxVolume) {
        if (maxVolume === void 0) { maxVolume = 1; }
        var _this = this;
        this.oscillators = [];
        this.lastVolume = 0;
        this.context = context;
        this.startElement = startElement;
        this.endElement = endElement;
        this.maxVolume = maxVolume;
        this.gainNode = context.createGain();
        this.gainNode.gain.setValueAtTime(0, context.currentTime);
        this.gainNode.connect(context.destination);
        if (context.createStereoPanner) {
            this.pannerNode = context.createStereoPanner();
            this.pannerNode.pan.setValueAtTime(position, context.currentTime);
            this.pannerNode.connect(this.gainNode);
        }
        this.scrollListener = function () { return _this.onScroll(); };
        document.addEventListener('scroll', this.scrollListener);
    }
    Ear.prototype.onScroll = function () {
        var s = this.startElement;
        var e = this.endElement;
        var sRect = s.getBoundingClientRect();
        var eRect = e.getBoundingClientRect();
        var totalDistance = e.offsetTop - s.offsetTop - window.innerHeight;
        var ramp = function (x, a) {
            if (a === void 0) { a = 50; }
            return (Math.pow(a, x) - 1) / (a - 1);
        };
        if (sRect.top < 0) {
            var volume = ramp(Math.min(Math.abs(sRect.top / totalDistance), 1));
            var adjusted = volume * this.maxVolume;
            if (adjusted > this.lastVolume) {
                this.lastVolume = adjusted;
            }
            this.setVolume(this.lastVolume);
        }
    };
    Ear.prototype.setVolume = function (value, length) {
        if (length === void 0) { length = 0.2; }
        this.gainNode.gain.setTargetAtTime(value, this.context.currentTime, length);
    };
    Ear.prototype.addOscillator = function (options) {
        var node = this.context.createOscillator();
        node.type = options.type;
        if (this.pannerNode)
            node.connect(this.pannerNode);
        else
            node.connect(this.gainNode);
        this.oscillators.push(new Oscillator(node, options));
    };
    Ear.prototype.addOscillatorP = function (frequency, period, amplitude, offset, method, type) {
        this.addOscillator({
            frequency: frequency, period: period, amplitude: amplitude, offset: offset, method: method, type: type
        });
    };
    Ear.prototype.mute = function () {
        this.gainNode.gain.setValueAtTime(0.0, this.context.currentTime);
        document.removeEventListener('scroll', this.scrollListener);
    };
    Ear.prototype.unmute = function () {
        this.gainNode.gain.setTargetAtTime(0.1, this.context.currentTime, 2);
    };
    Ear.prototype.start = function () {
        this.oscillators.forEach(function (o) { return o.start(); });
    };
    Ear.prototype.update = function () {
        var _this = this;
        this.oscillators.forEach(function (o) { return o.update(_this.context.currentTime); });
    };
    Ear.prototype.goNuclear = function () {
        this.setVolume(1, 5);
        document.removeEventListener('scroll', this.scrollListener);
    };
    return Ear;
}());
var Visualiser = /** @class */ (function () {
    function Visualiser(analyser, canvas) {
        this.started = false;
        this.playing = false;
        this.colour = '#FD5B76';
        this.analyser = analyser;
        this.canvas = canvas;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.fillStyle = '#000000';
        this.width = canvas.width;
        this.height = canvas.height;
        this.bufferSize = analyser.frequencyBinCount;
    }
    Visualiser.prototype.start = function () {
        var _this = this;
        this.started = true;
        this.updater = function () { return _this.update(); };
        requestAnimationFrame(this.updater);
    };
    Visualiser.prototype.pause = function () {
        this.started = false;
    };
    Visualiser.prototype.update = function () {
        var _a = this, width = _a.width, height = _a.height, bufferSize = _a.bufferSize, analyser = _a.analyser, ctx = _a.ctx;
        ctx.clearRect(0, 0, width, height);
        ctx.fillRect(0, 0, width, height);
        if (!this.started)
            return;
        var dataArray = new Uint8Array(bufferSize);
        analyser.getByteTimeDomainData(dataArray);
        var sliceWidth = width / bufferSize;
        var getPosition = function (index) {
            var value = dataArray[index] / 128.0;
            return value * height / 2;
        };
        var drawLine = function (colour, increment, offset) {
            ctx.strokeStyle = colour;
            ctx.beginPath();
            ctx.moveTo(0, getPosition(0));
            var i, midPoint;
            for (i = increment + offset; i < bufferSize; i += increment) {
                midPoint = i + increment / 2;
                ctx.quadraticCurveTo(i * sliceWidth, getPosition(dataArray[i]), midPoint * sliceWidth, getPosition(midPoint));
            }
            midPoint = bufferSize - 1;
            ctx.quadraticCurveTo(width, getPosition(dataArray[bufferSize - 1]), midPoint * sliceWidth, getPosition(midPoint));
            ctx.stroke();
        };
        drawLine('#5B5CFD', 32, 0);
        drawLine('#FD5B76', 64, 0);
        drawLine('#888888', 128, 0);
        requestAnimationFrame(this.updater);
    };
    return Visualiser;
}());
var TheHum = /** @class */ (function () {
    function TheHum() {
    }
    TheHum.prototype.initialise = function (droneElement, miseryElement, endElement, audioElement) {
        this.droneElement = droneElement;
        this.miseryElement = miseryElement;
        this.endElement = endElement;
        this.audioElement = audioElement;
        var AC = window.AudioContext || window.webkitAudioContext;
        this.context = new AC();
        var middleEar = new Ear(this.context, 0, droneElement, endElement, 0.20);
        var leftEar = new Ear(this.context, -1, droneElement, endElement, 0.20);
        var rightEar = new Ear(this.context, 1, droneElement, endElement, 0.20);
        middleEar.addOscillatorP(200, 0.5, 0, 0, PeriodicFunction.SINE, WaveType.SINE);
        middleEar.addOscillatorP(13530, 20, 100, 30, PeriodicFunction.COSINE, WaveType.SQUARE);
        middleEar.addOscillatorP(210, 0.7, 4, 2, PeriodicFunction.SINE, WaveType.SINE);
        leftEar.addOscillatorP(40, 0.5, 5, 2, PeriodicFunction.COSINE, WaveType.SAWTOOTH);
        rightEar.addOscillatorP(40, 0.53, 5, 2.2, PeriodicFunction.SINE, WaveType.SQUARE);
        leftEar.addOscillatorP(11175.303, 4000, 100, 20, PeriodicFunction.COSINE, WaveType.SQUARE);
        rightEar.addOscillatorP(15804.266, 4000, 100, 20, PeriodicFunction.SINE, WaveType.SQUARE);
        leftEar.addOscillatorP(698.456, 1, 0, 0, PeriodicFunction.COSINE, WaveType.SINE);
        rightEar.addOscillatorP(987.767, 1, 0, 0, PeriodicFunction.COSINE, WaveType.SINE);
        this.ears = [middleEar, leftEar, rightEar];
        this.ears.forEach(function (e) { return e.start(); });
        this.middleEar = middleEar;
        this.leftEar = leftEar;
        this.rightEar = rightEar;
        this.setupMusic();
        this.update();
    };
    TheHum.prototype.setupMusic = function () {
        var context = this.context;
        var audioElement = this.audioElement;
        var track = context.createMediaElementSource(audioElement);
        var analyser = context.createAnalyser();
        track.connect(analyser);
        analyser.connect(context.destination);
        this.audioElement = audioElement;
        this.audioElement.play();
        this.audioElement.pause();
        this.visualiser = new Visualiser(analyser, document.getElementById('visualiser'));
    };
    TheHum.prototype.startMusic = function () {
        this.audioElement.play();
        this.visualiser.start();
    };
    TheHum.prototype.pauseMusic = function () {
        this.audioElement.pause();
        this.visualiser.pause();
    };
    TheHum.prototype.update = function () {
        var _this = this;
        this.ears.forEach(function (e) { return e.update(); });
        requestAnimationFrame(function (e) {
            _this.update();
        });
    };
    TheHum.prototype.mute = function () {
        this.ears.forEach(function (e) { return e.mute(); });
    };
    return TheHum;
}());
exports.TheHum = TheHum;
