"use strict";
exports.__esModule = true;
require("styles/index.scss");
var humcontroller_1 = require("./humcontroller");
var started = false;
var brexitEl = document.getElementById('happenings');
var root = document.getElementById('root');
var splash = document.getElementById('splash');
var content = document.getElementById('content');
var cta = document.getElementById('cta');
var humController = new humcontroller_1.HumController();
var loading = true;
var animationFrame = 0;
var frames = ['-', '\\', '|', '/'];
var spin = function () {
    cta.innerText = frames[animationFrame] + ' loading ' + frames[animationFrame];
    animationFrame++;
    animationFrame %= 4;
    if (loading)
        requestAnimationFrame(spin);
    else
        cta.innerText = "click or touch anywhere to continue";
};
spin();
window.onload = function () {
    loading = false;
    cta.innerText = "click or touch anywhere to continue";
    splash.addEventListener('click', function () {
        splash.className = 'splash hidden';
        humController.initialise();
        setTimeout(function () {
            content.className = 'content active';
            content.style.opacity = "1";
        }, 200);
    });
};
